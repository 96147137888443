<template>
  <div class="app-box">
    <div class="borapm">
      <!-- 正常流程 -->
      <el-steps
        :active="active"
        finish-status="wait"
        process-status="finish"
        align-center
      >
        <el-step title="待付款">
          <template #icon>
            <div v-if="active == 0" class="baolee">1</div>
            <div v-else>1</div>
          </template>
        </el-step>
        <el-step title="已付款">
          <template #icon>
            <div v-if="active == 1" class="baolee">2</div>
            <div v-else>2</div>
          </template>
        </el-step>
        <el-step title="待发货">
          <template #icon>
            <div v-if="active == 2" class="baolee">3</div>
            <div v-else>3</div>
          </template>
        </el-step>
        <el-step title="已发货">
          <template #icon>
            <div v-if="active == 3" class="baolee">4</div>
            <div v-else>4</div>
          </template>
        </el-step>
        <el-step title="已完成">
          <template #icon>
            <div v-if="active == 4" class="baolee">5</div>
            <div v-else>5</div>
          </template>
        </el-step>
      </el-steps>
    </div>
    <!-- end订单 -->
    <ul class="orderBox">
      <!-- 待付款 -->
      <template v-if="dataIs == true">
        <van-empty description="暂无数据" />
      </template>
      <template v-else>
        <van-list
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <li v-for="(item, index) in listArr" :key="index">
            <div @click="details(item.id)">
              <p>
                <span class="titleyxing" v-if="item.state == 4">1</span>
                <span class="titleyxing" v-else-if="item.state == 3">2</span>
                <span class="titleyxing" v-else-if="item.state == 8">3</span>
                <span class="titleyxing" v-else-if="item.state == 7">4</span>
                <span class="titleyxing" v-else-if="item.state == 1">5</span>
                <span class="titleyxing" v-else-if="item.state == 2">6</span>
                <span class="titleyxing" v-else-if="item.state == 6">7</span>
                <span class="titleyxing" v-else-if="item.state == 5">8</span>
                <!-- 手动状态文字 -->
                <span class="shopping" v-if="item.state == 2">已驳回</span>
                <span class="shopping" v-else-if="item.state == 5">已取消付款</span>
                <span class="shopping" v-else-if="item.state == 6">已退款</span>

                <span class="shopping examine" v-else-if="item.state == 8">待发货</span>
                <span class="shopping examine" v-else-if="item.state == 4">待付款</span>
                <span class="shopping examine" v-else-if="item.state == 3">已付款</span>
                <span class="shopping examine" v-else-if="item.state == 7">已发货</span>
                <span class="shopping examine" v-else-if="item.state == 1">已完成</span>
              </p>
              <div class="TopBox">
                <div class="left">
                  <img
                    v-if="item.op_role == 3"
                    src="../../assets/img/yyzx.png"
                    alt=""
                  />
                  <img
                    v-if="item.op_role == 8"
                    src="../../assets/img/operateOper.png"
                    alt=""
                  />
                </div>
                <div class="right">
                  <p class="rightText">
                    <span class="spanColor">签约城市:</span
                    >{{
                      item.province == null
                        ? "全国"
                        : item.province + item.city + item.area
                    }}
                  </p>
                  <p class="rightText">
                    <span class="spanColor">合同编号:</span>{{ item.sn_code }}
                  </p>
                  <p class="rightText_p">
                    <span class="spanColor">{{ item.truename }}:</span
                    >{{ item.phone }}
                  </p>
                  <p class="textRight">
                    订单金额：<span class="rightColor">¥{{ item.price }}</span>
                  </p>
                  <template v-if="item.state == '4'">
                    <p class="timeBox" v-if="timeDiff(item.create_date)">
                      <van-count-down :time="timeDiff(item.create_date)" />
                    </p>
                  </template>
                </div>
              </div>
            </div>
            <template v-if="item.state == '7'">
              <div class="timeBox1">
                <van-button
                  class="bottomone1"
                  type="default"
                  size="mini"
                  round
                  @click="jempt(item.express_sn)"
                  >查看物流</van-button
                >
                <van-button
                  class="bottomone2"
                  type="default"
                  size="mini"
                  round
                  @click="receiving(item.id)"
                  >确认收货</van-button
                >
              </div>
            </template>
          </li>
        </van-list>
      </template>
    </ul>
    <!-- end -->
    <Loding ref="loding" @load="load"></Loding>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import { SettledList } from "@/request/api";
import moment from "moment";
import Loding from "@/components/Loding/Loding";
export default {
  components: {
    Loding,
  },
  data() {
    return {
      active: 5,
      time: 30 * 60 * 60 * 1000,
      listArr: [],
      orderType: true,
      dataIs: "",
      page: {
        size: "5",
        page: "1",
      },
      loading: false,
      finished: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    load() {
      this.$refs.loding.loding = true;
      this.$refs.loding.loderror = false;
      this.getList();
    },
    onLoad() {
      this.page.page++;
      setTimeout(() => {
        SettledList(this.page).then((res) => {
          if (res.code == 1005) {
            this.finished = true;
            this.$refs.loding.loding = false;
            return;
          }
          this.listArr = this.listArr.concat(res.data.data);
          this.loading = false;
        });
      }, 1000);
    },
    details(id) {
      this.$router.push({ path: "/TypePage", query: { id: id } });
    },
    getList() {
      SettledList(this.page)
        .then((res) => {
          if (res.code == 1005) {
            this.dataIs = true;
            this.$refs.loding.loding = false;
            return;
          }
          this.listArr = res.data.data;
          this.$refs.loding.loding = false;
        })
        .catch((error) => {
          this.$refs.loding.loderror = true;
          this.$refs.loding.loding = false;
        });
    },
    confirmClick() {
      Dialog.alert({
        message: "合同已签收，可确认收货",
      }).then(() => {
        this.$router.push("/Success");
        // on close
      });
    },
    seeLogistics() {
      this.$router.push("/Logistics");
    },
    //过滤时间
    timeDiff(timefor) {
      let diff = moment().diff(moment(timefor));
      if (diff >= 1800000) {
        return false;
      } else {
        this.orderType = true;
        let dataTime = 1800000 - diff;
        return dataTime;
      }
    },
    jempt(express_sn) {
      if (express_sn == undefined || "") {
        Toast.fail("暂无物流单号,请等待添加");
        return;
      }
      window.location = "https://m.kuaidi100.com/result.jsp?nu=432107460720582";//拼接跳转
        // this.$router.push({path:'/Logistics',query:{express:express_sn}});
    },
    receiving(id) {
      Dialog.confirm({
        message: "是否确认收货？",
      })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #edf2f5ff;
  box-sizing: border-box;
  overflow: hidden;
}
.borapm {
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
}
.borapm /deep/.el-step__icon {
  background: #edf2f5ff;
}
.orderBox {
  overflow: hidden;
}
.orderBox li {
  background: #fff;
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.orderBox li .titleyxing {
  background: #0e8affff;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  width: 0.6rem;
  height: 0.6rem;
  text-align: center;
  line-height: 0.6rem;
  color: #fff;
  font-size: 0.4rem;
}
.orderBox li .titleyxing::after {
  content: "";
  display: inline-block;
  position: absolute;
  right: -0.6rem;
  top: 50%;
  transform: translate(0, -50%);
  width: 0.8rem;
  height: 2px;
  background: #0e8affff;
}
.shopping {
  float: right;
  font-size: 0.4rem;
  color: #fb4a51ff;
}
.TopBox {
  box-sizing: border-box;
  background: #fff;
  overflow: hidden;
  padding-top: 10px;
}
.TopBox .left {
  float: left;
}
.TopBox .right {
  margin-left: 2.3rem;
  font-size: 0.4rem;
}
.TopBox .left img {
  width: 2rem;
}
.right .spanColor {
  color: #333b5cff;
}
.TopBox .service {
  font-size: 0.43rem;
  color: #333b5cff;
  line-height: 1rem;
  border-bottom: 1px solid #f3f3f5ff;
}
.textRight {
  text-align: right;
  margin-top: 0.3rem;
}
.rightColor {
  color: #fb4a51ff;
}
.timeBox {
  float: right;
  background: rgba(14, 138, 255, 0.1);
  padding: 0.1rem 0.2rem;
  box-sizing: border-box;
  border-radius: 20px;
}
.timeBox ::v-deep.van-count-down {
  color: #0e8affff;
  font-size: 0.35rem;
}
.examine {
  color: #0e8affff;
}
.annBottom {
  text-align: right;
}
.annBottom .marLeft {
  margin-left: 0.2rem;
}
.annBottom ::v-deep.van-button {
  padding: 0.1rem 0.3rem;
}
.timeBox1 {
  color: red;
  text-align: right;
  margin-top: 0.3rem;
}
.rightText {
  margin-bottom: 0.1rem;
}
.rightText_p {
  margin-top: 0.3rem;
}

.timeBox1 .bottomone1 {
  border: 1px solid #464a5bff;
  color: #464a5bff;
  padding: 0.3rem 0.3rem;
  font-size: 0.35rem;
}
.timeBox1 .bottomone2 {
  border: 1px solid #0e8affff;
  color: #0e8affff;
  padding: 0.3rem 0.3rem;
  font-size: 0.35rem;
  margin-left: 0.4rem;
}
</style>